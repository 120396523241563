import { AlertLink } from '@/components/alert_links/alert_link'
import { AlertLinkRelationship, AlertLinkType } from '@/generated/enums'

// Filter out cross org links for non-employee view
export const filteredAlertLinks = (
  alertLinks: AlertLink[],
  alertUuid: string,
  isEmployeeView: boolean,
  orgId: string,
) =>
  alertLinks.filter((alertLink) => {
    const linkedAlert = getLinkedAlert(alertLink, alertUuid)

    const is_cross_org = linkedAlert.organization.id !== orgId
    return isEmployeeView || !is_cross_org
  })

export const getLinkedAlert = (alertLink: AlertLink, alertUuid: string) =>
  alertLink.alertId1.id === alertUuid ? alertLink.alertId2 : alertLink.alertId1

export const getRelationshipReadable = (alertLink: AlertLink, alertUuid) => {
  switch (alertLink.relationship) {
    case AlertLinkRelationship.PEER_TO_PEER:
      return 'Peer'
    case AlertLinkRelationship.PARENT_CHILD:
      return alertLink.alertId1.id === alertUuid ? 'Child' : 'Parent'
  }
}

export const AUTOGENERATED_LINK_TYPES = [AlertLinkType.HTML_SIMILARITY]
