import { AlertLink, LinkedAlertDetails } from './alert_link'
import {
  Flex,
  Text,
  Th,
  Tr,
  Td,
  Heading,
  Link,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'

import { AlertLinkAdder } from './alert_link_adder'
import DeleteButton from '../shared/delete_button'
import { DOPPEL_LINK, DOPPEL_SECURE } from '@/utils/style'
import { getDoppelLink, getExternalReportId } from '@/utils/reports/report_utils'
import DoppelLink from '../report_detail/doppel_link'
import { shouldDefangLinks, shouldDefaultToHttp } from '@/utils/feature_flags'
import { useIsEmployeeView } from '@/hooks/id_token_claims'
// import TableTimestamp from '../tables/table_timestamp'
import { getTimestampDisplay } from '@/utils/time'
import { useDeleteAlertLinkMutation } from '@/generated/graphql'
import DoppelAlertDialog from '../shared/doppel_alert_dialog'
import { useRef, useState } from 'react'
import { SpoofDetailModalTable } from '../web2/detail_modal/spoof_detail_modal_table'
import { AlertLinkRelationship, AlertLinkType } from '@/generated/enums'

export default function AlertLinksTable({
  alertLinks,
  alert,
  org,
  refetchAlertLinks,
  showHeader = true,
}: {
  alertLinks: AlertLink[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  alert: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  org: any
  refetchAlertLinks: () => void
  showHeader?: boolean
}) {
  const [isEmployeeView] = useIsEmployeeView()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const [deleteAlertLinkMutation] = useDeleteAlertLinkMutation()
  const cancelRef = useRef()

  const alertId = alert.id

  const header = 'Linked Alerts'
  const columnNames = [
    'Created At',
    'Alert',
    'Identifier',
    ...(isEmployeeView ? ['Link Type'] : []),
    ...(isEmployeeView ? ['Relationship'] : []),
    ...(isEmployeeView ? ['Additional Info'] : []),
    ...(isEmployeeView ? ['Delete'] : []),
  ]

  const getOtherAlert = (alertLink: AlertLink) => {
    return alertLink.alertId1.id === alertId ? alertLink.alertId2 : alertLink.alertId1
  }

  const [alertLinkToDelete, setAlertLinkToDelete] = useState<AlertLink | null>(null)

  const alertLinkToDeleteOtherAlert = alertLinkToDelete
    ? getOtherAlert(alertLinkToDelete)
    : null

  const alertLinkToDeleteExternalId = alertLinkToDeleteOtherAlert
    ? getExternalReportId(
        alertLinkToDeleteOtherAlert.organization?.abbr_name,
        alertLinkToDeleteOtherAlert.external_id,
      )
    : null

  const deleteAlertLink = () => {
    const alertLinkId = alertLinkToDelete?.id
    deleteAlertLinkMutation({
      variables: {
        alert_link_id: alertLinkId,
      },
    })
      .then(() => {
        refetchAlertLinks()
        onClose()
        toast({
          title: 'Successfully deleted alert link',
          status: 'success',
          isClosable: true,
        })
      })
      .catch((error) => {
        onClose()
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          isClosable: true,
        })
      })
  }

  const orgId = org.id

  const linkedAlertIdColumn = (linked_alert: LinkedAlertDetails) => {
    const external_report_id = getExternalReportId(
      linked_alert.organization?.abbr_name,
      linked_alert.external_id,
    )
    const product = linked_alert.platform?.product
    const link = getDoppelLink(linked_alert.id, product)

    return (
      <Link color={DOPPEL_LINK} cursor="pointer" href={link}>
        {external_report_id}
      </Link>
    )
  }

  // TODO: support Telco links display
  const linkedAlertUrlColumn = (linked_alert: LinkedAlertDetails) => {
    const url = linked_alert?.spoofMatches?.[0]?.fullUrl?.unsanitizedUrl

    if (!url) {
      return <Text>N/A</Text>
    }
    return (
      <DoppelLink
        href={url} // Use a default value (e.g., '#' or an empty string) if url is null
        isDefanged={!isEmployeeView && shouldDefangLinks(orgId)}
        name={url} // Use a fallback name if url is null
        noOfLines={3}
        shouldDefaultToHttp={shouldDefaultToHttp(orgId)}
      />
    )
  }

  const getAdditionalInfo = (
    alertLinkType: AlertLinkType,
    linked_alert: LinkedAlertDetails,
  ) => {
    // Only HTML_SIMILARITY links has additional info for now
    if (alertLinkType !== AlertLinkType.HTML_SIMILARITY) {
      return <Text>N/A</Text>
    }
    const url = linked_alert?.spoofMatches?.[0]?.fullUrl?.siteHtmlUrl

    if (!url) {
      return <Text>N/A</Text>
    }
    return <DoppelLink href={url} name="Scraped HTML Link" />
  }

  const getRelationshipReadable = (alertLink: AlertLink) => {
    switch (alertLink.relationship) {
      case AlertLinkRelationship.PEER_TO_PEER:
        return 'Peer'
      case AlertLinkRelationship.PARENT_CHILD:
        return alertLink.alertId1.id === alertId ? 'Child' : 'Parent'
    }
  }

  // Filter out cross org links for non-employee view
  const filteredAlertLinks = alertLinks.filter((alertLink) => {
    const other_alert = getOtherAlert(alertLink)

    const is_cross_org = other_alert.organization.id !== orgId
    return isEmployeeView || !is_cross_org
  })

  const alertName = getExternalReportId(org.abbr_name, alert.external_id)

  const data = filteredAlertLinks.map((alertLink) => {
    const other_alert = getOtherAlert(alertLink)
    const relationship = getRelationshipReadable(alertLink)

    return (
      <Tr key={`state-change-${alertLink.id}`}>
        <Td color={DOPPEL_SECURE} fontSize={12}>
          {getTimestampDisplay(alertLink.createdAt)}
        </Td>

        <Td fontSize={12}>{linkedAlertIdColumn(other_alert)}</Td>

        <Td fontSize={12}>{linkedAlertUrlColumn(other_alert)}</Td>

        {isEmployeeView && (
          <>
            <Td fontSize={12}>{alertLink.type}</Td>

            <Td fontSize={12}>{relationship}</Td>

            <Td fontSize={12}>{getAdditionalInfo(alertLink.type, other_alert)}</Td>

            <Td>
              <DeleteButton
                deleteFunc={() => {
                  setAlertLinkToDelete(alertLink)
                  onOpen()
                }}
              />
            </Td>
          </>
        )}
      </Tr>
    )
  })

  const columns = (
    <Tr>
      {columnNames.map((name) => (
        <Th key={name}>{name}</Th>
      ))}
    </Tr>
  )

  return (
    <Flex direction="column" paddingTop="5" width="100%">
      {filteredAlertLinks.length > 0 ? (
        <SpoofDetailModalTable
          columns={columns}
          data={data}
          header={showHeader ? header : null}
        />
      ) : (
        <Flex direction="column" paddingBottom={4} width="100%">
          {showHeader && (
            <Heading as="h4" mr={2} size="md">
              {header}
            </Heading>
          )}

          <Text fontSize={15} marginTop={4}>
            None
          </Text>
        </Flex>
      )}

      {isEmployeeView && (
        <AlertLinkAdder
          alertId={alertId}
          alertName={alertName}
          refetchAlertLinks={refetchAlertLinks}
        />
      )}

      <DoppelAlertDialog
        body={`Are you sure you want to delete the link to alert ${alertLinkToDeleteExternalId}?`}
        cancelRef={cancelRef}
        confirmAction={deleteAlertLink}
        header="Delete Alert Link"
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  )
}
