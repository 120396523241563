import { HStack } from '@chakra-ui/react'
import {
  CLASSIFICATION_TO_LABEL,
  Classification,
  Severity,
} from '../../utils/constants'
import {
  DOPPEL_BREACH_RED,
  DOPPEL_FIREWALL_ORANGE,
  DOPPEL_SECURE,
  DOPPEL_DIM_YELLOW,
} from '../../utils/style'
import EditDropdown from '../shared/edit_dropdown'
import { DoppelTag } from '../shared/doppel_tag'

const filterValueToDisplay = (value: string) => {
  const modifiedValue = value === 'unknown' ? 'unverified' : value
  return modifiedValue
}

// define a react component that is an entity status tag color coded based on the entity status
export function SpoofStatusTagWithMenu({
  classification,
  loading,
  onChange,
}: {
  classification: Classification
  loading?: boolean
  onChange: (value: Classification) => void
}) {
  return (
    <HStack spacing={2}>
      <DoppelTag
        bgColor={getColorForSpoofStatus(classification)}
        color={'#FFFFFF'}
        loading={loading}
        tag={CLASSIFICATION_TO_LABEL[classification]}
      />

      <EditDropdown
        currentValue={classification}
        getColorForValues={getColorForSpoofStatus}
        isForEmployee
        onChange={onChange}
        valueToDisplayFunction={filterValueToDisplay}
        values={[
          Classification.ACTIVE.toString(),
          Classification.PARKED.toString(),
          Classification.DOWN.toString(),
        ]}
      />
    </HStack>
  )
}

export function getColorForSpoofStatus(spoofStatus: Classification): string {
  let bgColor = DOPPEL_SECURE
  switch (spoofStatus) {
    case Classification.SUSPICIOUS:
      bgColor = DOPPEL_BREACH_RED
      break
    default:
      bgColor = DOPPEL_SECURE
      break
  }
  return bgColor
}

export function getColorForSeverity(severity: Severity): string {
  const severityColorMap: { [key in Severity]: string } = {
    [Severity.LOW]: DOPPEL_DIM_YELLOW,
    [Severity.MEDIUM]: DOPPEL_FIREWALL_ORANGE,
    [Severity.HIGH]: DOPPEL_BREACH_RED,
  }

  return severityColorMap[severity]
}
